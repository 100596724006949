import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMission() {
  const toast = useToast()
  const fetchContent = async () => {
    try {
      const response = await store.dispatch('app-mission/fetchContent');
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateContent = async (content) => {
    try {
      const response = await store.dispatch('app-mission/updateContent', content);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    fetchContent,
    updateContent,
  }
}
