<template>
  <div>
    <validation-observer ref="form">
      <b-row>
        <b-col sm="12" xl="9">
          <b-card>
              <b-form>
                <b-row>
                  <b-col sm="12">
                    <h3>Vision</h3>
                  </b-col>
                  <b-col sm="12">
                    <b-tabs pills>
                      <b-tab title="English" active>
                        <b-form-group>
                          <label for="content-description">Description <span class="text-red">*</span></label>
                          <validation-provider
                            #default="{ errors }"
                            name="Vision description"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="vision.english"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Note about what we do"
                              rows="5"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Thai">
                        <b-form-group>
                          <label for="content-description">Description</label>
                          <b-form-textarea
                            v-model="vision.thai"
                            placeholder="Note about what we do"
                            rows="5"
                          />
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Chinese">
                        <b-form-group>
                          <label for="content-description">Description</label>
                          <b-form-textarea
                            v-model="vision.chinese"
                            placeholder="Note about what we do"
                            rows="5"
                          />
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
              </b-form>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" xl="9">
          <b-card>
              <b-form>
                <b-row>
                  <b-col sm="12">
                    <h3>Mission</h3>
                  </b-col>
                  <b-col sm="12">
                    <b-tabs pills>
                      <b-tab title="English" active>
                        <b-form-group>
                          <label for="content-description">Description <span class="text-red">*</span></label>
                          <validation-provider
                            #default="{ errors }"
                            name="Mission description"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="mission.english"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Note about what we do"
                              rows="5"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Thai">
                        <b-form-group>
                          <label for="content-description">Description</label>
                          <b-form-textarea
                            v-model="mission.thai"
                            placeholder="Note about what we do"
                            rows="5"
                          />
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Chinese">
                        <b-form-group>
                          <label for="content-description">Description</label>
                          <b-form-textarea
                            v-model="mission.chinese"
                            placeholder="Note about what we do"
                            rows="5"
                          />
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
              </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="fixed-footer justify-end">
      <div>
        <b-button
          variant="outline-secondary"
          @click="() => this.$router.go(0)"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="ml-1"
          variant="success"
          @click="handleUpdate"
        >
          <span>Save</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BFormTextarea, BFormFile, BImg, BBadge, BTab, BTabs
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, regex } from '@validations'
import useMission from './useMission'
import missionStoreModule from './missionStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormTextarea,
    BBadge,
    BFormFile,
    BImg,
    BTab,
    BTabs,

    vSelect,
  },
  props: {},
  data() {
    return {
      required,
      email,
      regex,
      vision: {
        english: '',
        thai: '',
        chinese: '',
      },
      mission: {
        english: '',
        thai: '',
        chinese: '',
      }
    }
  },
  methods: {
    async handleUpdate() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const { status } = await this.updateContent({
        vision: this.vision,
        mission: this.mission,
      })
      if (status !== 200) {
        this.makeToast(false)
        return;
      }

      this.makeToast(true)
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Update Succeeded!' : 'Updated failed!' }</h4>
              <div class="ml-1 font-14px">{success ? 'Vision/Mission have been updated.' : 'Unexpected error occurred.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }

    try {
      const { data } = await this.fetchContent();
      this.vision = data.vision;
      this.mission = data.mission;
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  setup() {
    const MISSION_STORE_MODULE_NAME = 'app-mission'

    // Register module
    if (!store.hasModule(MISSION_STORE_MODULE_NAME)) store.registerModule(MISSION_STORE_MODULE_NAME, missionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MISSION_STORE_MODULE_NAME)) store.unregisterModule(MISSION_STORE_MODULE_NAME)
    })

    const { fetchContent, updateContent } = useMission();
    const toast = useToast()

    return {
      toast,
      fetchContent,
      updateContent,
    }
  },
}
</script>